import { Grid } from "@material-ui/core";
import SearchField from "../Inputs/SearchField";
import FullPageLoader from "../common/FullPageLoader/FullPageLoader";
import styles from "./CashFlow.module.css";
import CashFlowFilter from "./CashFlowFilter/CashFlowFilter";
import CashFlowTable from "./CashFlowTable";
import { useFilteredData } from "./hooks";
import CashFlowTimeout from "./CashFlowTimeout";
import ProgressLoader from "../common/ProgressLoader/ProgressLoader";

const CashFlow = () => {
	const {
		data,
		filters,
		clearFilters,
		handlers,
		search,
		loading,
		meta,
		sorter,
		loadError,
		request,
	} = useFilteredData();

	return (
		<>
			<Grid container spacing={2} direction="column">
				<Grid
					container
					item
					justify="space-between"
					alignItems="center"
				>
					<Grid item>
						<span className={styles.header}>Wallet Cash Flow</span>
					</Grid>
					<Grid item xs={3}>
						<SearchField
							label="Transaction Ref No."
							onChange={(e: any) =>
								handlers.search(e.target.value)
							}
							value={search}
						/>
					</Grid>
				</Grid>
				<Grid item xs>
					<CashFlowFilter
						clearFilters={clearFilters}
						filters={filters}
						handlers={handlers}
					/>
				</Grid>
				<Grid item xs>
					<CashFlowTable
						data={data}
						handlers={handlers}
						meta={meta}
						sorter={sorter}
					/>
				</Grid>
			</Grid>
			{/* <FullPageLoader open={loading} /> */}
			<ProgressLoader open={loading} completeProgress={!loading}/>
			<CashFlowTimeout
				open={loadError}
				onClose={() => handlers.setLoadError(false)}
				retryFn={request}
			/>
		</>
	);
};

export default CashFlow;
