import { createSlice } from "@reduxjs/toolkit";
import { duration } from "moment";

// Initial State
const initialState = {
	open: false,
	message: "",
	severity: "info",
	icon: null,
	duration: 0,
};

const alertSlice = createSlice({
	name: "alert",
	initialState,
	reducers: {
		showAlert: (state, action) => {
			console.log("Show alert", action)
			state.open = true;
			state.message = action.payload.message;
			state.severity = action.payload.severity ? action.payload.severity : "info";
			state.icon = action.payload.icon || null;
			state.duration = action.payload.duration || 3000;
		},
		hideAlert: (state) => {
			state.open = false;
			state.message = "";
			state.severity = "";
			state.icon = null;
		},
	},
});

// Export actions generated by createSlice
export const { showAlert, hideAlert } = alertSlice.actions;

// Export the reducer to be included in the store
export default alertSlice.reducer;
