import { useEffect, useState } from "react";
import {
	Dialog,
	Box,
	Typography,
} from "@material-ui/core";
import styles from "./TimeCheckerModal.module.css";
import calendarTimeIcon from "../../assets/icons/calendar-time.svg";
import successIcon from "../../assets/icons/ic-success.svg";
import moment from 'moment'
import ApiClient from "../../helpers/ApiClient";

const TimeCheckerModal = ({handleTimeAdjust}) => {
    const [openModal, setOpenModal] = useState(false)
    const [isTimeAdjusted, setIsTimeAdjusted] = useState(false);

    useEffect(() => {
        // Function to fetch the time from the API
        const fetchTime = async () => {
            try {
            let localTime =  null;
            localTime =  new Date();
            const response = await ApiClient.get('v3/get-server-time')
                .then((data) => {
                    return data
                })
                .catch((error) => {
                    console.error('Error fetching server time:', error);
                });
            const serverTime = response.data.date_time;
            checkLocalAndServerTime(serverTime, localTime)
            } catch (error) {
                console.error('Error fetching time:', error);
                setIsTimeAdjusted(true)
                handleTimeAdjust(true)
                setOpenModal(false)
            }
        };
    
        // Call the function immediately to avoid initial delay
        fetchTime();
    
        // A : Set up the interval to fetch the time every 3 seconds and has stopper when isTimeAdjusted = TRUE
        const interval = setInterval(() => {
            if (!isTimeAdjusted) {
                fetchTime();
            } else {
                clearInterval(interval);
            }
        }, 5000);

        // B: Set up the interval to fetch the time every 3 seconds and no stopper just continue call the function fetchTime()
        // const interval = setInterval(fetchTime, 3000);

        // Cleanup interval on component unmount
        return () => clearInterval(interval);

        // A : Add `isTimeAdjusted` if setup has has stopper
        // B: Remove `isTimeAdjusted` if setup has no stopper
    }, [isTimeAdjusted]);

    const checkLocalAndServerTime = (serverTime, localTime) => {
        if(isWithinOneMinute(moment(serverTime) , moment(localTime))){
            setIsTimeAdjusted(true)
            handleTimeAdjust(true)
            setTimeout(()=>{
                setOpenModal(false)
            },5000)
        }else{
            setOpenModal(true)
            setIsTimeAdjusted(false)
            handleTimeAdjust(false)
        }
    }

    const isWithinOneMinute = (timeA, timeB) => {
        const differenceInMilliseconds = Math.abs(timeA - timeB);
        const differenceInSeconds = differenceInMilliseconds / 1000;
        const differenceInMinutes = differenceInMilliseconds / 60000;
        let result = true
        if (differenceInMinutes > 1) {
            result = false
        }
        return result
    };

	return (
		<Dialog
			disableBackdropClick
			classes={{ paper: styles.dialogPaper }}
			open={openModal}
			maxWidth="lg"
		>
			<Box>
				<img src={!isTimeAdjusted ? calendarTimeIcon : successIcon} alt="icon" className={!isTimeAdjusted ? styles.img : styles.imgSuccess} />
			</Box>
			<Box>
                {!isTimeAdjusted ? (
                    <Typography className={styles.header}>Setup device time</Typography>
                ) : (
                    <Typography className={styles.headerSuccess}>
                        Device Date and Time is aligned with the BFA Server Time.
                    </Typography>
                )}
			</Box>
			<Box>
                {!isTimeAdjusted && (
                    <Typography className={styles.body}>
                        Your device date/time does not match Bayad FA’s system time. Before you proceed with log-in, kindly adjust your device time to avoid any issues: <br/>
                        <br/>
                        Step 1: Open Date & Time Settings. <br/>
                        Step 2: Change Date & Time. <br/>
                        Step 3: Turn On "Set time Automatically and click Sync Now". <br/>
                        Step 4: Wait for 30 seconds to 1 minute, you will be redirected to the log-in page. <br/>
                        <br/>
                        You may also contact Bayad Helpdesk for assistance to set-up your device time.
                    </Typography>
                )}
			</Box>
		</Dialog>
	);
};

export default TimeCheckerModal;
