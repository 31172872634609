import React, { useEffect } from "react";
import { Snackbar, Alert } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { hideAlert } from "../../../redux/modules/alert"; // Update the path as needed
import styles from "./Snackbar.module.css";

// SEVERITY CHOICES - success, info, warning, error
const CustomSnackbar = () => {
	const dispatch = useDispatch();
	const { open, message, severity, icon, duration, position } = useSelector(
		(state) => state.alert
	);

	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		dispatch(hideAlert());
	};

	return (
		<Snackbar
			open={open}
			autoHideDuration={duration}
			onClose={handleClose}
			anchorOrigin={
				position || { vertical: "bottom", horizontal: "left" }
			} // Default position
			ContentProps={{
				sx: {
					display: "block",
					textAlign: "center",
				},
			}}
		>
			<Alert
				sx={{
					...(severity === "info" && { background: "#F6F6F6" }),
					boxShadow: 2,
				}}
				severity={severity}
				icon={icon}
				action={
					<IconButton
						size="small"
						aria-label="close"
						color="inherit"
						onClick={handleClose}
					>
						<CloseIcon fontSize="small" />
					</IconButton>
				}
			>
				<p className={styles.fontStyle}>{message}</p>
			</Alert>
		</Snackbar>
	);
};

export default CustomSnackbar;
