import bfaLogo from "../../assets/logo/logo-bayadFA.svg";
import styles from "./Login.module.css";
import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

class LoginPane extends Component {
  render() {
    return (
      <>
        <Grid item xs={12}>
          <div className={styles.message}>Welcome KaBayad!</div>
        </Grid>
        <Grid item xs={12}>
          <div style={{ textAlign: "center", marginTop: "45px" }}>
            <Button
              variant="contained"
              color="primary"
              style={{ width: "75%" }}
			  disabled={this.props.btnDisabled}
              onClick={this.props.onLoginClick}
            >
              Login with UAM
            </Button>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={styles.captcha}>
            This site is protected by reCAPTCHA and the{" "}
            <a
              className={styles.anchor}
              href="https://policies.google.com/privacy"
              target="_blank"
              rel="noreferrer"
            >
              Google Privacy Policy
            </a>{" "}
            and{" "}
            <a
              className={styles.anchor}
              href="https://policies.google.com/terms"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Service apply.
            </a>
          </div>
        </Grid>
      </>
    );
  }
}

export default LoginPane;
