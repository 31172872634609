import React, { Component } from "react";
import { createRandomString, getChallenge } from "../../utils/codeGenerator";
import Grid from "@material-ui/core/Grid";
import failedIcon from "../../assets/icons/ic-failed.svg";
import styles from "./Login.module.css";
import background from "../../assets/images/login/bg-login.png";
import bfaLogin from "../../assets/images/login/img-login.png";
import bfaLogo from "../../assets/logo/logo-bayadFA.svg";
import LoginPane from "./LoginPane";
import TpaSelectPane from "./TpaSelectPane";
import ContactUs from "../ContactUs/ContactUs";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "../TermsAndConditions/TermsAndConditions";
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";
import SessionExpiredModal from "../SessionTimeout/SessionExpiredModal";
import { setSessionStorage, getSessionStorage } from "../../utils/storage";
import AccessDeniedDialog from "../Dialog/Dialog";
import { connect } from "react-redux";
import { hideInvalidIpModal } from "../../redux/modules/userInfo";
import AccessChecker from "./AccessChecker";
import { logOut } from "../../utils/logout";
import TimeCheckerModal from "../Modal/TimeCheckerModal";

class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isTermsConditionsActive: false,
			isPrivacyActive: false,
			isContactUsActive: false,
			codeChallenge: null,
			state: null,
			isExpired: false,
			enableButton: true,
		};

		this.onLogin = this.onLogin.bind(this);
		this.onClickContact = this.onClickContact.bind(this);
		this.onClickPrivacy = this.onClickPrivacy.bind(this);
		this.onClickTerms = this.onClickTerms.bind(this);
		this.onTimeAdjust = this.onTimeAdjust.bind(this);
	}

	componentDidMount() {
		const codeVerifier = createRandomString(128);
		const state = createRandomString(40);
		const codeChallenge = getChallenge(codeVerifier);
		this.setState({
			codeChallenge: codeChallenge,
			state: state,
		});
		setSessionStorage("state", state);
		setSessionStorage("codeVerifier", codeVerifier);

		const urlParams = new URLSearchParams(window.location.search);
		if (urlParams.get("session")) {
			this.setState({
				isExpired: true,
			});
		}
	}

	onTimeAdjust(check) {
		this.setState({ enableButton: check });
	}

	onClickContact() {
		this.setState({ isContactUsActive: !this.state.isContactUsActive });
	}

	onClickPrivacy() {
		this.setState({ isPrivacyActive: !this.state.isPrivacyActive });
	}

	onClickTerms() {
		this.setState({
			isTermsConditionsActive: !this.state.isTermsConditionsActive,
		});
	}

	onLogin() {
		let stateUrl = this.state.state;
		let codeChallengeUrl = this.state.codeChallenge;

		if (!getSessionStorage("state") || !getSessionStorage("codeVerifier")) {
			console.log("recreate state and code challenge..");
			const codeVerifier = createRandomString(128);
			const state = createRandomString(40);
			const codeChallenge = getChallenge(codeVerifier);
			stateUrl = state;
			codeChallengeUrl = codeChallenge;
			setSessionStorage("state", state);
			setSessionStorage("codeVerifier", codeVerifier);
		}

		sessionStorage.removeItem("token");
		const loginUrl = process.env.REACT_APP_LOGIN_URI;
		window.location =
			loginUrl +
			"?client_id=" +
			process.env.REACT_APP_APP_KEY +
			"&code_challenge=" +
			codeChallengeUrl +
			"&code_challenge_method=S256" +
			"&response_type=code" +
			"&scope=" +
			"&state=" +
			stateUrl +
			"&redirect_uri=" +
			encodeURIComponent(process.env.REACT_APP_CALLBACK_URL);
	}

	render() {
		return (
			<>
				<AccessDeniedDialog
					imgSrc={failedIcon}
					title="Access Denied!"
					message={
						<>
							Your IP Address is not registered.
							<br />
							Kindly coordinate with your supervisor for
							<br />
							the registration of your IP Address.
						</>
					}
					open={this.props.showInvalidIpModal}
					toggleDialog={this.props.hideInvalidIpModal}
				/>
				{!this.props.userInfo && <TimeCheckerModal handleTimeAdjust={(check)=> this.onTimeAdjust(check)}/>}
				{/* <AccessChecker
					accessType="User"
					value={this.props.userInfo ? this.props.userInfo.uuid : ""}
					callback={logOut}
				/> */}
				<SessionExpiredModal isExpired={this.state.isExpired} />
				<Router>
					<Switch>
						<Route exact path="/">
							<div
								style={{
									backgroundImage: `url(${background})`,
									backgroundRepeat: "no-repeat",
									backgroundSize: "100% 100%",
								}}
							>
								<Grid
									container
									spacing={0}
									direction="row"
									alignItems="center"
									justify="center"
									style={{ minHeight: "100vh" }}
								>
									<Grid item xs={12}>
										<Grid
											container
											justify="center"
											spacing={0}
											direction="row"
										>
											<Grid
												item
												className={styles.leftLogin}
											>
												{this.state
													.isContactUsActive ? (
													<ContactUs
														onBackClick={
															this.onClickContact
														}
													/>
												) : (
													<Grid
														container
														justify="center"
														direction="column"
													>
														<Grid item xs={12}>
															<div
																style={{
																	textAlign:
																		"center",
																	marginTop:
																		"46px",
																}}
															>
																<img
																	src={
																		bfaLogo
																	}
																	alt="BFA Logo"
																/>
															</div>
															{this.props
																.userInfo ? (
																<>
																	<TpaSelectPane />
																</>
															) : (
																<LoginPane
																	onLoginClick={
																		this
																			.onLogin
																	}
																	btnDisabled={
																		!this
																			.state
																			.enableButton
																	}
																/>
															)}
														</Grid>
													</Grid>
												)}
											</Grid>
											<Grid item>
												<Grid container>
													<div className={styles.box}>
														<img
															src={bfaLogin}
															alt="BFA Welcome"
														/>
														<div
															className={
																styles.text1
															}
														>
															MERALCO FAST PAY
															BILLS
														</div>
														<div
															className={
																styles.text2
															}
														>
															Were featuring
															complete access for
															all of your online
															transactions pay
															bills, send money &
															receive remittances
															anywhere & anywhere.
														</div>
													</div>
												</Grid>
											</Grid>
										</Grid>
										<Grid
											container
											justify="center"
											style={{ marginTop: "27px" }}
										>
											<Grid item>
												<div className={styles.rights}>
													© 2021 Bayad. All rights
													reserved.
												</div>
											</Grid>
											<Grid item>
												<div className={styles.links}>
													<span
														style={{
															cursor: "pointer",
														}}
														onClick={
															this.onClickContact
														}
													>
														{" "}
														Contact Us{" "}
													</span>{" "}
													|{" "}
													<Link
														to="/terms-and-conditions"
														style={{
															color: "inherit",
															textDecoration:
																"none",
														}}
													>
														{" "}
														Terms & Conditions{" "}
													</Link>{" "}
													|{" "}
													<Link
														to="/privacy-policy"
														style={{
															color: "inherit",
															textDecoration:
																"none",
														}}
													>
														{" "}
														Privacy Policy{" "}
													</Link>
												</div>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</div>
						</Route>
						<Route path="/privacy-policy">
							<PrivacyPolicy />
						</Route>
						<Route path="/terms-and-conditions">
							<TermsAndConditions />
						</Route>
					</Switch>
				</Router>
			</>
		);
	}
}

export default connect(
	(state) => ({ showInvalidIpModal: state.userInfo.showInvalidIpModal }),
	{
		hideInvalidIpModal,
	}
)(Login);
